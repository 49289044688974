.expanded-row {
  .ant-table-cell {
    padding-top: 16px; // Aumente conforme necessário
    padding-bottom: 16px; // Aumente conforme necessário
  }
}

.file-item {
  margin-top: -16px;
  background: #e7e7e7;
  line-height: 0px;
  color: black;
}

.file-container {
  background: #f5f5f5 !important;
}