@import '../../styles/variaveis';

.page-title-element {
  width: 100%;
  font-size: 16px;
  display: inline-block;
  gap: 4px;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 2px solid $cor-primary;
  margin-bottom: 14px;

  .go-back-button {
    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }

  p {
    margin: 0px;
    margin-top: -10px;
    padding: 0px 10px 10px 0px;
    font-size: 14px;
  }

  h1 {
    margin: 0px;
    font-size: 24px;
    font-family: $font-title;
    font-weight: bold;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    gap: 6px;


    span {
      color: black;
      font-weight: normal;
    }
  }
}

@media screen and (max-width: $brackpoint-md) {
  .page-title-element {
    font-size: 14px;

    h1 {
      font-size: 18px;
    }
  }
}