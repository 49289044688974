@import 'variaveis';

.MuiFormControlLabel-root.hide {
  display: none;
}

.table-container {
  overflow-x: auto;
  // width: calc(100% - 20px);
  // position: absolute;
  padding-bottom: 30px;
}

label.table-pagination_number {
  font-size: 12px;
  width: 34px;
}

.table-pagination_number.active {
  background-color: $cor-secundary;
}

.table-pagination {
  display: flex;
  justify-content: end;
  margin: 10px 0px;
  bottom: 4px;
  right: 20px;
  gap: 4px;

  label {
    background-color: rgb(209, 209, 209);
  }

  div {
    background-color: rgb(209, 209, 209);
    border-radius: 40px;
    padding: 6px;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
  }
}

.table-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.129);
}

.table-container::-webkit-scrollbar-thumb {
  background-color: $cor-secundary-2;
  outline: 1px solid $cor-secundary-2;
  border-radius: 20px;
}

table.table_styled.table_selectable tr td {
  cursor: pointer;
}

table.table_styled {
  width: 100%;
  border-collapse: collapse;

  thead {
    th {

      font-size: 12px !important;
      background: $cor-primary;
      border: none;
      text-align: left;
      position: sticky;
      top: 0;
      z-index: 2;

      &.hide {
        display: none;
      }

      &:first-child {
        border-radius: 6px 0px 0px 0px;
      }

      &:last-child {
        border-radius: 0px 6px 0px 0px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      span.th-title {
        color: white;
        display: flex;
        gap: 4px;
        align-items: center;
        padding: 8px 12px;
        border-radius: 20px;
        white-space: nowrap;
      }

      .tableSeachIccon {
        color: white;
        cursor: pointer;
      }

      .container_column_seach {
        display: block;
        // position: absolute;
        // top: -5px;
        // z-index: 80;
      }

      &.tr_search {
        background-color: transparent;
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 14px;
        padding: 0px;
        border-bottom: 1px solid #cacaca;

        &.active {
          background-color: #f4f4f4;
        }

        div {
          //border-bottom: 1px solid #cacaca;
          height: 100%;
          padding: 4px 10px;
          min-height: 30px;
          display: flex;
          align-items: center;
        }

        &:first-child {
          border-left: 1px solid #cacaca;

          div {
            //border-left: 1px solid #cacaca;
          }
        }

        &:last-child {
          border-right: 1px solid #cacaca;

          div {
            //border-right: 1px solid #cacaca;
          }
        }
      }
    }
  }

}

///// responsive table
@media screen and (max-width: $brackpoint-md) {
  .table-container {
    max-height: calc(100vh - 240px);
  }
}

// CORREÇÃO ANT TABLE  + MODAL BACKDROP
.ant-table {
  z-index: 10 !important;
  position: relative !important;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 7px 7px !important;
}

ant-table-tbody > tr > td, .ant-table-thead > tr > th {
  padding: 10px !important;
}

tr:nth-child(odd) {
  background: #F1E6FF;
}

tr:nth-child(even) {
  background: white;
}

tr {
  background: white !important;
}

thead[class*="ant-table-thead"] td {
  background-color: $cor-primary !important;
  color: white !important;
  border-color: #000;
  text-align: center;
}


thead[class*="ant-table-thead"] th {
  background-color: $cor-primary !important;
  color: white !important;
  border-color: #000;
  text-align: center;
}

.table_btn {
  margin: 0 !important;
}

.ant-btn {
  margin: 0;
}

.ant-table-tbody > tr:hover > td {
  //color: rgba(255, 0, 255, 0.16);
  //background-color: #00e6ff;
}