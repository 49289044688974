@import '../../styles/variaveis';

.login-page {
  background-image: url(../../assets/img/bg-wave.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-page-logo {
    max-width: 220px;
  }

  .login-page-container {
    width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    background: rgb(240, 240, 240);
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(240, 240, 240, 1) 100%);
    padding: 60px 80px;
    border-radius: 20px;
    box-shadow: 0px 4px 2px 1px #d8d8d8;

    .login-page-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 30px
    }

    .login-divider-container {
      height: 300px;
      width: 1px;
      background: rgb(240, 240, 240);
      background: linear-gradient(0deg, #00e6ff 0%, #5b61f1 50%, #00e6ff 100%);
    }

    .login-form {
      width: 300px
    }


  }
}

@media screen and (max-width: $brackpoint-lg) {
  .login-page {
    background-size: contain;
    background-position: right bottom;

    .login-page-logo {
      max-width: 200px;
    }

    .login-page-container {
      width: 600px;
      max-width: 80%;
      margin: 20px;

      .login-page-content {
        display: flex;
        flex-direction: column;
        gap: 30px
      }

      .login-divider-container {
        height: 1px;
        width: 100%;
        background: rgb(240, 240, 240);
        background: linear-gradient(0deg, #00e6ff 0%, #5b61f1 50%, #00e6ff 100%);
      }

      .login-form {
        width: 400px
      }


    }
  }
}

@media screen and (max-width: $brackpoint-sm) {
  .login-page {
    .login-page-container {
      width: 600px;
      max-width: 80%;
      margin: 20px;
      padding: 30px 40px;

      .login-form {
        width: 100%
      }

    }
  }
}
