@import '../../styles/variaveis';

.notifications_container {
  .notifications_icon {
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 80;
  }
}

.notificationItem {
  padding: 10px 20px;
  background-color: #f4f4f4;
  margin-bottom: 2px;
  color: #525252;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .notificationItem_title {
    font-weight: bold;
    color: black;
  }

  .notificationItem_text {
    font-size: 14px;
  }

  .notificationItem_date {
    font-size: 12px;
  }

  &.orange {
    background-color: rgb(255, 229, 200);
  }

  &.green {
    background-color: rgb(188, 255, 188);
  }

  &.red {
    background-color: rgb(255, 213, 213);
  }
}