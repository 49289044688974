@import "variaveis";
@import "material";
@import "tables";

body {
  margin: 0;
  font-family: $font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $cor-gray-8;
}

h1 {
  font-family: $font-title;
  font-weight: bold;
  font-size: 36px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

a {
  text-decoration: none;
}

.cor-success {
  color: $cor-success;
}

.cor-error {
  color: $cor-danger;
}

.bold {
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-none {
  flex: none;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.shrink {
  flex-shrink: 1;
}

.shrink-0 {
  flex-shrink: 0;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-start {
  justify-items: flex-start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: flex-start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.self-baseline {
  align-self: baseline;
}

.gap-0 {
  gap: 0px !important;
}

.gap-2 {
  gap: 2px !important;
}

.gap-4 {
  gap: 4px !important;
}

.gap-6 {
  gap: 6px !important;
}

.gap-8 {
  gap: 8px !important;
}

.gap-10 {
  gap: 10px !important;
}

.gap-12 {
  gap: 12px !important;
}

.gap-14 {
  gap: 14px !important;
}

.gap-16 {
  gap: 16px !important;
}

.gap-18 {
  gap: 18px !important;
}

.gap-20 {
  gap: 20px !important;
}

.gap-30 {
  gap: 30px !important;
}

.gap-40 {
  gap: 40px !important;
}

.gap-x-0 {
  column-gap: 0px !important;
}

.gap-x-2 {
  column-gap: 2px !important;
}

.gap-x-4 {
  column-gap: 4px !important;
}

.gap-x-6 {
  column-gap: 6px !important;
}

.gap-x-8 {
  column-gap: 8px !important;
}

.gap-x-10 {
  column-gap: 10px !important;
}

.gap-x-12 {
  column-gap: 12px !important;
}

.gap-x-15 {
  column-gap: 14px !important;
}

.gap-x-16 {
  column-gap: 16px !important;
}

.gap-x-18 {
  column-gap: 18px !important;
}

.gap-x-20 {
  column-gap: 20px !important;
}

.gap-y-0 {
  row-gap: 0px !important;
}

.gap-y-2 {
  row-gap: 2px !important;
}

.gap-y-4 {
  row-gap: 4px !important;
}

.gap-y-6 {
  row-gap: 6px !important;
}

.gap-y-8 {
  row-gap: 8px !important;
}

.gap-y-10 {
  row-gap: 10px !important;
}

.gap-y-12 {
  row-gap: 12px !important;
}

.gap-y-14 {
  row-gap: 14px !important;
}

.gap-y-16 {
  row-gap: 16px !important;
}

.gap-y-18 {
  row-gap: 18px !important;
}

.gap-y-20 {
  row-gap: 20px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-2 {
  padding: 2px !important;
}

.p-4 {
  padding: 4px !important;
}

.p-6 {
  padding: 6px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-14 {
  padding: 14px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-18 {
  padding: 18px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-30 {
  padding: 30px !important;
}

.p-40 {
  padding: 40px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.px-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.px-6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.px-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.px-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-18 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.py-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.py-8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.py-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-14 {
  padding-top: 14px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-18 {
  padding-top: 18px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-14 {
  padding-bottom: 14px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-2 {
  padding-right: 2px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pr-14 {
  padding-right: 14px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-18 {
  padding-right: 18px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-14 {
  padding-left: 14px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-18 {
  padding-left: 18px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.m-0 {
  margin: 0px !important;
}

.m-2 {
  margin: 2px !important;
}

.m-4 {
  margin: 4px !important;
}

.m-6 {
  margin: 6px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-12 {
  margin: 12px !important;
}

.m-14 {
  margin: 14px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-18 {
  margin: 18px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-30 {
  margin: 30px !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.w-0 {
  width: 0px !important;
}

.w-200 {
  width: 200px !important;
}

.min-w-0 {
  min-width: 0px !important;
}

.fontSmall {
  font-size: 12px !important;
}

.font-12 {
  font-size: 12px;
}

.w-full {
  width: 100% !important;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.loadding {
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel {
  min-height: 10px;

  background: #fbfbfb;
  border-radius: 10px;
  box-shadow: 0px 4px 2px 1px #d8d8d8;

  .panel-header {
    background-color: $cor-primary;
    padding: 10px 20px;
    border-radius: 10px 10px 0 0;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    span {
      color: $cor-primary;
      font-size: 12px;
    }
  }

  .panel-body {
    padding: 24px 20px;

    &.divider {
      border-bottom: 1px solid $cor-gray-3;
    }

    &.loadding {
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .panel-footer {
    padding: 14px 20px;
    border-top: 1px solid $cor-gray-1;
  }
}

.badge {
  background-color: $cor-primary;
  color: white;
  border-radius: 20px;
  padding: 3px 8px;
}

.btn-main {
  background: linear-gradient(254.09deg, $cor-secundary-2 8.52%, $cor-secundary-2 91.11%);
  border-radius: 4px;

  &:disabled {
    background-color: rgb(189, 189, 189) !important;
    background: rgb(189, 189, 189) !important;
  }
}

.btn-green {
  background: linear-gradient(254.09deg, $cor-success 8.52%, $cor-success 91.11%);
  border-radius: 4px;

  &:disabled {
    background-color: rgb(189, 189, 189) !important;
    background: rgb(189, 189, 189) !important;
  }
}

.btn-left {
  display: flex;
  gap: 10px;
  justify-content: start !important;
}

.labelAllis {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  justify-content: space-between;

  svg {
    font-size: 14px;
    color: #838383;
    cursor: pointer;
  }

  &.big {
    svg {
      font-size: 18px;
      color: #585858
    }
  }
}

.divider {
  width: 100%;
  border-top: 1px solid #e1e4e8;
  margin: 5px 0px;
}

.badge {
  border-radius: 4px;
  background-color: #E1D6F8;
  color: black;

  &.badge-info {
    background-color: #c7f9ff;
  }

  &.badge-success {
    background-color: #b6e4ba;
  }

  &.badge-error {
    background-color: #e6b6b6;
  }

  &.badge-warning {
    background-color: #f3dbbf;
  }

  &.badge-warning2 {
    background-color: #fee7ff;
  }
}

.float-left {
  float: left;
}

.sm-min-500 {
  min-width: unset;
}

@media (max-width: $brackpoint-lg) {
  .md-hidden {
    display: none;
  }
}

@media (min-width: $brackpoint-sm) {
  .sm-min-500 {
    min-width: 500px;
  }
}

@media (max-width: $brackpoint-sm) {
  .sm-hidden {
    display: none;
  }
}

@media (max-width: $brackpoint-xs) {
  .xs-hidden {
    display: none;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: $cor-secundary-2;
  outline: 1px solid $cor-secundary-2;
  border-radius: 20px;
}

:where(.css-dev-only-do-not-override-1vr7spz).ant-picker-large {
  padding: 5px 11px 5px !important;
}

.fakeCheckbox {
  border: 1px solid #e21ae7;
  background-color: white;
  border-radius: 4px;
  width: 14px;
  height: 14px;

  svg {
    max-width: 14px;
    max-height: 14px;
    fill: $cor-primary;
  }

  &.active {
    background-color: #00e7ff;
  }
}