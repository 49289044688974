@import '../styles/variaveis';

#wrapper {
  width: calc(100% - 200px);
  margin-left: 200px;
  margin-top: 0px;
  transition: all 0.5s;

  .page-container {
    padding: 0px;
  }
}

.wrapper-container-content {
  //display: flex;
  background-color: white;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  background-image: url(../assets/img/bg-wave.svg);
}

.wrapper-container-contentAdmin {
  background-color: #fff;
  min-height: 100vh;
  width: calc(100% - 270px);
}


.wrapper-page-content {
  position: relative;
  // background-color: blue;
  flex: 1;
  padding: 0px 20px 20px 20px;

  &.bgwhite {
    background-color: white;
  }

  &.page-dash {
    padding: 0px 20px 0px 20px;
  }

  &.wrapper-page-divided {
    float: left;
    width: calc(100% - 300px);
    transition: all 0.3s;

    &.reductedSizeMenu {
      width: calc(100% - 70px);
    }

    .table-container {
      max-height: calc(100vh - 270px);
    }
  }
}

.wrapper-page-content-interna {
  padding: 10px;
  position: relative;
}

#dragbar {
  background-color: transparent;
  height: 100%;
  width: 3px;
  cursor: col-resize;
  position: absolute;
  right: 0px;
}

.wrapper-container-submenuAdmin.hide {
  #dragbar {
    display: none;
  }
}

.wrapper-container-submenu {
  .close-title {
    display: none;
  }

  &.hide {
    .close-title {
      display: block;
      font-weight: bold;
      color: $cor-primary;
      transform: rotate(-90deg);
      position: absolute;
      left: -20px;
      top: 120px;
      font-size: 14px;
    }
  }
}


@media screen and (max-width: $brackpoint-md) {
  .wrapper-container-contentAdmin {
    width: calc(100%);
    min-height: inherit;
  }

  body {
    &.m-l-o {
      #wrapper {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
  #wrapper {
    width: 100%;
    margin-left: 0px;

    .page-container {
      padding: 0px 0px 0px 0px;
    }
  }
  .wrapper-page-content {
    padding: 70px 20px 20px 20px;

    &.page-dash {
      padding: 60px 20px 14px 20px;
    }

    &.wrapper-page-divided {
      float: left;
      width: calc(100% - 40px);

      &.reductedSizeMenu {
        width: calc(100% - 40px);
      }

      .table-container {
        max-height: unset;
      }
    }
  }

  #dragbar {
    display: none;
  }

}
