@import '../../styles/variaveis';

.btn-open-envirioments {
  position: fixed;
  background-color: $cor-primary;
  border-radius: 30px;
  right: 16px;
  top: 8px;
  padding: 0px 20px;
  color: white;

  .MuiButtonBase-root {
    opacity: 1 !important;
  }

  .btn-open-envirioments_icon {
    display: none;
  }

  .btn-open-envirioments_text {
    color: white !important;
    display: block;
  }
}

.enviriomentsList_title {
  display: flex;
  gap: 10px;
  padding: 16px;
  border-bottom: 1px solid #dfdfdf;

  .svg {
    cursor: pointer;
  }

  .MuiTypography-root {
    font-size: 12px;
  }

}

.enviriomentsList_container {
  .MuiList-root {
    padding-top: 0px;
  }

  .MuiListItem-root {
    border-bottom: 1px solid #dfdfdf;
  }

  li {
    .MuiButtonBase-root {
      font-size: 14px;

      .MuiTypography-root {
        font-size: 14px;
      }

      svg {
        width: 14px;
        display: none;
      }

      &:hover {
        background-color: $cor-primary;
        color: white;
      }

      &.active {
        svg {
          display: block;
        }
      }
    }

    &.active {
      background-color: $cor-primary;
      color: white;

      .MuiButtonBase-root {
        svg {
          display: block;
        }
      }
    }
  }
}

@media screen and (max-width: $brackpoint-md) {
  .btn-open-envirioments {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px;
    border-radius: 30px;
    right: 50px;
    top: 68px;
    padding: 0px;

    .btn-open-envirioments_icon {
      width: 25px;
      height: 25px;
      padding: 0px;
      display: block;

      svg {
        width: 17px;
      }
    }

    .btn-open-envirioments_text {
      display: none;
    }
  }
}
