@import '../../styles/variaveis';


@media screen and (max-width: $brackpoint-lg) {

}

.reproccess-div {
  display: inline-flex;
}

#reprocessButton {
  border-radius: 20px 0px 0px 20px !important;
  height: 37px !important;
  margin-bottom: 3px !important;
}

.btn-dashboard-action {
  display: inline;
  background-color: $cor-secundary-2;
  border-radius: 30px;
  border: white none;
  right: 16px;
  top: 14px;
  padding: 3px 20px;
  color: white;

  .MuiButtonBase-root {
    opacity: 1 !important;
  }

  .btn-open-envirioments_icon {
    display: none;
  }

  .btn-open-envirioments_text {
    color: white !important;
    display: block;
  }
}

@import '../../styles/variaveis';
.btn-dashboard-action:hover {
  background-color: $cor-primary;
  cursor: pointer;
}