@import '../../styles/variaveis';

.system-main-header {
  min-height: $header-min-height;
  padding: 0px;
  gap: 10px;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 8;

  width: 200px;
  background-color: $cor-background;
  min-height: 100vh;

  .system-main-menu-icon {
    display: none;
    align-self: center;
    color: black;
    background-color: #d5d5d5;
    margin: 0px;
    cursor: pointer;
    width: 48px;
    height: 48px;

    &:hover {
      background-color: $cor-primary;
      color: white;
    }
  }

  .system-main-header-logo {
    margin: 8px;
    padding: 4px;
    height: 40px;
    width: 40px;
    background-color: $cor-primary-dark;
    border-radius: 50px;
    transition: all 0.5s;

    &:hover {
      border-radius: 10px;
    }

    img {
      width: 100%;
    }
  }

  .system-main-header-logo-2 {
    margin: 40px 0 10px 0;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;

    &:hover {
      border-radius: 10px;
    }

    img {
      width: 80%;
    }
  }

  .header-logo-mobile {
    display: none;
  }

  .system-main-header-user-button, .system-main-header-logout-button {
    display: flex;
    width: 200px;
    align-items: center;
    color: white;
    cursor: pointer;
    margin: 0px;
    height: 40px;
    position: fixed;
    bottom: 0px;

    .icon-name {
      background-color: #d5d5d5;
      padding: 0px 12px;
      color: #5A5A5A;
      gap: 10px;
      display: flex;
      align-items: center;
      justify-content: start;
      height: 40px;
      width: 200px;
      border-radius: 0px;

      &:hover {
        background-color: $cor-primary;
        color: white;
      }
    }
  }

  .system-main-header-user-button {
    bottom: 60px;
  }
}

@media screen and (max-width: $brackpoint-md) {
  .system-main-header {
    min-height: $header-min-height;
    width: 100%;
    gap: 4px;
    display: flex;
    justify-content: space-between;

    .system-main-menu-icon {
      display: flex;
    }

    .header-logo-destop {
      display: none;
    }

    .header-logo-mobile {
      display: block;
    }

    .system-main-header-logo-2 {
      margin: 6px 10px;
      width: 56px;
      text-align: center;
      position: fixed;

      &:hover {
        border-radius: 10px;
      }

      img {
        width: 80%;
      }
    }


    .system-main-header-user-button, .system-main-header-logout-button {
      position: relative;
      display: flex;

      align-items: center;
      color: white;
      cursor: pointer;
      margin: 6px;
      height: 46px;
      width: 46px;
      bottom: 0px;

      .icon-name {
        background-color: #d5d5d5;
        padding: 0px 12px;
        color: #5A5A5A;
        gap: 10px;
        display: flex;
        align-items: center;
        justify-content: start;
        height: 46px;
        width: 46px;
        border-radius: 46px;

        &:hover {
          background-color: $cor-primary;
          color: white;
        }

        span {
          display: none;
        }
      }
    }

    .system-main-header-user-button {
      bottom: unset;
      position: absolute !important;
      right: 60px;
      top: 0px;
    }
  }
}

@media (min-width: 768px) {

  .system-main-header {
    z-index: 99;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .subMenu-icon {
      display: none;
    }
  }

  .toggle_mainMenu {

    display: flex;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background-color: white;
    position: absolute;
    right: -10px;
    top: 33px;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px;
    cursor: pointer;

    svg {
      width: 18px;
    }

  }

  body.menu_colapse {
    .toggle_mainMenu {
      transform: rotate(180deg);

      margin: 20px 0px 20px 12px;
      display: flex;
      width: 25px;
      height: 25px;
      border-radius: 25px;
      background-color: white;
      position: relative;
      right: unset;
      top: unset;
      align-items: center;
      justify-content: center;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px;
      cursor: pointer;

    }

    #wrapper {
      width: calc(100% - 50px) !important;
      margin-left: 50px !important;
    }

    .header-logo-destop {
      display: none;
    }

    .header-logo-mobile {
      display: block !important;
    }

    .system-menu-sidebar {
      width: 50px;

      ul li {
        position: relative;
      }

      ul li a span, ul li button span {
        display: none;
      }
    }

    .system-main-header {
      width: 50px;
      overflow: hidden;
      transition: width 0.2s;

      .subMenu-icon-arrow {
        display: none;
      }

      .subMenu-icon {
        display: block;
      }

      .system-main-header-logout-button {
        width: 50px;
        transition: width 0.2s;

        span {
          display: none;
        }
      }

      ul {
        li {
          &.show {
            ul {
              display: none;
            }
          }
        }
      }

      // &:hover {
      //   width: 200px;
      //   z-index: 99999;
      //   box-shadow: 0px 0px 20px darken(#00000020, 2%);

      //   .header-logo-destop {
      //     display: block;
      //   }
      //   .system-main-header-logo-2 {
      //     display: flex;
      //     justify-content: center;
      //   }
      //   .header-logo-mobile {
      //     display: none !important;
      //   }

      //   ul {
      //     li {
      //       &.show {
      //         ul {
      //           display: block;
      //         }
      //       }
      //     }
      //   }

      //   .subMenu-icon-arrow {
      //     display: block;
      //   }
      //   .subMenu-icon {
      //     display: none;
      //   }
      //   .system-main-header-logout-button {
      //     width: 200px;
      //     span {
      //       display: block;
      //     }
      //   }

      //   .system-menu-sidebar {
      //     width: 200px;
      //     ul li {
      //       position: relative;
      //     }
      //     ul li a span, ul li button span {
      //       display: block;
      //     }
      //   }
      // }
    }
  }

}