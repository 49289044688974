@import '../../styles/variaveis';


.btn-toggle-subMenu {
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  right: -13px;
  top: 33px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px;
  cursor: pointer;

  svg {
    width: 18px;
  }
}


.conteiner-submenu-modal {
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    font-size: 15px;

    li {
      cursor: pointer;

      .submenu-title-group {
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 4px 0px;

        &:hover {
          transform: scale(1.05);
        }
      }

      svg {
        transition: all 0.3s;
      }
    }
  }

  ul.submenu-interno {
    max-height: 0px;
    overflow: hidden;
    font-size: 14px;
    padding-left: 4px;
    transition: all 0.3s;

    li {
      cursor: pointer;
      transition: all 0.3s;
      padding: 2px 8px;
      border-radius: 20px;
      margin: 4px 5px 4px 4px;

      &:hover {
        transform: scale(1.05);
        color: $cor-secundary-2;
      }

      &.active {
        background-color: $cor-secundary-2;
        color: white;
        border: 1px solid $cor-secundary-2;
      }
    }
  }

  ul {
    li.show {
      ul.submenu-interno {
        max-height: unset;
      }

      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.wrapper-container-submenu {
  background-color: rgb(250, 250, 250);
  display: flex;
  position: relative;
  flex-direction: column;
  padding-top: 60px;
  z-index: 9;
  padding-left: 22px;
  padding-right: 18px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px;
  width: 220px;
  transition: all 0.4s;

  .submenu-close-icon {
    display: none;
    cursor: pointer;
  }

  h2 {
    font-weight: normal;
    font-size: 20px;
  }

  .submenu-content {
    padding-left: 6px;
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $cor-secundary-2;
      outline: 1px solid $cor-secundary-2;
      border-radius: 20px;
    }

  }

  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
    font-size: 15px;

    li {
      cursor: pointer;

      .submenu-title-group {
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 4px 0px;

        &:hover {
          transform: scale(1.05);
        }
      }

      svg {
        transition: all 0.3s;
      }
    }
  }

  ul.submenu-interno {
    max-height: 0px;
    overflow: hidden;
    font-size: 14px;
    padding-left: 4px;
    transition: all 0.3s;

    li {
      cursor: pointer;
      transition: all 0.3s;
      padding: 2px 8px;
      border-radius: 20px;
      margin: 4px 5px 4px 4px;

      &:hover {
        transform: scale(1.05);
        color: $cor-secundary-2;
      }

      &.active {
        background-color: $cor-secundary-2;
        color: white;
        border: 1px solid $cor-secundary-2;
      }
    }
  }

  ul {
    li.show {
      ul.submenu-interno {
        max-height: unset;
      }

      svg {
        transform: rotate(90deg);
      }
    }
  }

  &.hide {
    width: 0px;
    padding-left: 14px;
    padding-right: 14px;

    .wrapper-content-submenu {
      width: 0px;
      overflow: hidden;
    }

    .btn-toggle-subMenu svg {
      transform: rotate(180deg);
    }

    // overflow: hidden;
  }
}

@media screen and (max-width: $brackpoint-md) {

  .wrapper-container-submenu {
    padding-top: 76px;
    position: absolute;
    right: 12px;
    top: 68px;
    padding: 0px;
    box-shadow: none;
    background-color: transparent;

    width: 0px;

    .submenu-close-icon {
      display: block;
    }

    ul.submenu-interno li {
      margin: 4px 20px;
    }

    .submenu-content {
      min-height: 50px;
      max-height: 80%;
      overflow: hidden auto;
      padding: 0 20px;
    }

    .btn-toggle-subMenu {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      right: unset;
      left: -25px;
      top: 0px;
    }

    .wrapper-content-submenu {
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px;
      // border-radius: 10px;
      // padding: 0px 20px 10px 20px

      border-radius: 0px;
      padding: 4%;
      position: fixed;
      top: 0px;
      left: 0px;
      width: 92%;
      height: 92%;
      display: none;
    }

    &.hide {
      width: 0px;
      padding-left: 14px;
      padding-right: 14px;

      .wrapper-content-submenu {
        display: none;
      }

      .btn-toggle-subMenu {
        left: 0px;
      }
    }
  }

}
