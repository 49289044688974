.custom-input-label {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  background-color: white;
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: -5px;
}

.antd-select-text span {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400;
  font-size: 1rem;
}

.ant-select-selector {
  background-color: white !important;
  border: 1px solid #c4c4c4 !important;
  border-radius: 3px !important;
}

.ant-select-selector:hover {
  background-color: white !important;
  border: 1px solid #000000 !important;
  border-radius: 3px !important;
}


.ant-select-focused .ant-select-selector {
  border: 2px solid #1976d2 !important;
}

.error-border label {
  color: red !important;
  font-weight: bold !important;
}

.error-border {
  color: red !important;
  font-weight: bold !important;
}