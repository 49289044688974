@import '../../styles/variaveis';

.table_btn {
  margin: 0 !important;
}

.ant-btn {
  margin: 0;
}

.ant-table-tbody > tr:hover > td {
  //color: rgba(255, 0, 255, 0.16);
  //background-color: #00e6ff;
}


.est_totais_item {
  background-color: white;
  border-radius: 8px;
  padding: 10px 14px;
  width: fit-content;
  box-shadow: 0px 0px 6px darken($cor-cold-white, 4%);
  flex: 1;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &.ALL {
    border-bottom: 4px solid;
    border-color: #1b2836;

    .est_totais_value {
      color: #1b2836;
    }

    &.active {
      background-color: #1b2836;
      color: white;
      font-weight: bold;

      .est_totais_value {
        color: white;
      }
    }
  }

  &.CNPJ {
    border-bottom: 4px solid;
    border-color: #02e4ff;

    .est_totais_value {
      color: #02e4ff
    }

    &.active {
      background-color: #02e4ff;
      color: white;
      font-weight: bold;

      .est_totais_value {
        color: white;
      }
    }
  }

  &.CPF {
    border-bottom: 4px solid;
    border-color: #02e4ff;

    .est_totais_value {
      color: #02e4ff
    }

    &.active {
      background-color: #02e4ff;
      color: white;
      font-weight: bold;

      .est_totais_value {
        color: white;
      }
    }
  }

  &.HIGIENIZADOS {
    border-bottom: 4px solid;
    border-color: #8bc34a;

    .est_totais_value {
      color: #8bc34a
    }

    &.active {
      background-color: #8bc34a;
      color: white;
      font-weight: bold;

      .est_totais_value {
        color: white;
      }
    }
  }

  &.BAIXADOS {
    border-bottom: 4px solid;
    border-color: red;

    .est_totais_value {
      color: red
    }

    &.active {
      background-color: red;
      color: white;
      font-weight: bold;

      .est_totais_value {
        color: white;
      }
    }
  }

  &.PARA_ATUALIZAR {
    border-bottom: 4px solid;
    border-color: red;

    .est_totais_value {
      color: red
    }

    &.active {
      background-color: red;
      color: white;
      font-weight: bold;

      .est_totais_value {
        color: white;
      }
    }
  }


  .est_totais_value {
    font-weight: bold;
    font-size: 24px;
  }

  .est_totais_label {
    font-size: 12px;
    // max-width: 80px;
  }

}
