@import '../../styles/variaveis';

.container-visualizadores {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 4px;

  .visualizadores_item {
    font-size: 14px;
    cursor: pointer;
    padding: 8px;
    border: transparent;
    border-bottom: 1px solid #ccc;
    background-color: white;
    width: 100%;
    text-align: left;
    outline: none;
  }
}

.lista-entidades-vinculadas-dash {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  font-size: 12px;
  margin-top: 10px;

  .entidade-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2px;
    padding: 0px 2px 0px 10px;
    background-color: #e2e2e2;
    border-radius: 4px;

    svg {
      font-size: 16px;
    }

    &.tipo-equipe {
      background-color: #f4d8f2;
    }
  }
}

.TableList_title {
  display: flex;
  gap: 10px;
  padding: 16px;
  border-bottom: 1px solid #dfdfdf;

  .svg {
    cursor: pointer;
  }

  .MuiTypography-root {
    font-size: 12px;
  }

}

@media screen and (max-width: $brackpoint-lg) {

}
