@import '../../styles/variaveis';

.report-style-class {
  height: calc(100vh - 90px);
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgb(212, 212, 212);

  iframe {
    border: 0px;
    border-radius: 8px;

    &:fullscreen {
      border-radius: 0px;
    }
  }
}

.fullscrenDashBtn {
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;

  svg {
    font-size: 30px;
  }
}

@media screen and (max-width: $brackpoint-md) {
  .report-style-class {
    height: calc(100vh - 140px);
  }
}

:fullscreen {
  .report-style-class {
    border: none;
    height: 100%;
    width: 100%;
    border-radius: 0px;

    iframe {
      border-radius: 0px;
    }
  }
}