@import '../../styles/variaveis';

.box_type_chamado {
  background-color: #eeeeee;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
  cursor: pointer;
  font-weight: bold;
  border: 2px solid #eeeeee;

  &.active {
    border: 2px solid #d614e1;
    background-color: white;
  }
}

.logItem {
  padding: 10px 20px;
  background-color: #f4f4f4;
  margin-bottom: 2px;
  color: #525252;

  .logItem_title {
    font-weight: bold;
    color: black;
  }

  .logItem_text {
    font-size: 14px;
  }

  .logItem_date {
    font-size: 12px;
  }

  &.orange {
    background-color: rgb(255, 229, 200);
  }

  &.green {
    background-color: rgb(188, 255, 188);
  }

  &.red {
    background-color: rgb(255, 213, 213);
  }
}

.chamados_totais_item {
  background-color: white;
  border-radius: 8px;
  padding: 10px 14px;
  width: fit-content;
  box-shadow: 0px 0px 6px darken($cor-cold-white, 4%);
  flex: 1;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;

  &.ALL {
    border-bottom: 4px solid;
    border-color: #1b2836;

    .chamados_totais_value {
      color: #1b2836;
    }

    &.active {
      background-color: #1b2836;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }

  &.IN_PROGRESS {
    border-bottom: 4px solid;
    border-color: #02e4ff;

    .chamados_totais_value {
      color: #02e4ff
    }

    &.active {
      background-color: #02e4ff;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }

  &.FINISHIED {
    border-bottom: 4px solid;
    border-color: #8bc34a;

    .chamados_totais_value {
      color: #8bc34a
    }

    &.active {
      background-color: #8bc34a;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }

  &.CANCELED {
    border-bottom: 4px solid;
    border-color: red;

    .chamados_totais_value {
      color: red
    }

    &.active {
      background-color: red;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }

  &.CANCELED_PARTIAL {
    border-bottom: 4px solid;
    border-color: red;

    .chamados_totais_value {
      color: red
    }

    &.active {
      background-color: red;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }

  &.AWAITING_CANCELATION_APROVEMENT {
    border-bottom: 4px solid;
    border-color: #ff9a15;

    .chamados_totais_value {
      color: #ff9a15
    }

    &.active {
      background-color: #ff9a15;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }

  &.AWAITING_FINISH_APROVEMENT {
    border-bottom: 4px solid;
    border-color: #ff9a15;

    .chamados_totais_value {
      color: #ff9a15
    }

    &.active {
      background-color: #ff9a15;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }

  &.IN_REVISION {
    border-bottom: 4px solid;
    border-color: #ff9a15;

    .chamados_totais_value {
      color: #ff9a15
    }

    &.active {
      background-color: #ff9a15;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }

  &.AWAITING_REVISION {
    border-bottom: 4px solid;
    border-color: #d614e1; //#fffb15;
    .chamados_totais_value {
      color: #d614e1; //#fffb15;
    }

    &.active {
      background-color: #d614e1; // #fffb15;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }

  &.AWAITING_START {
    border-bottom: 4px solid;
    border-color: #d614e1; //#fffb15;
    .chamados_totais_value {
      color: #d614e1; //#fffb15;
    }

    &.active {
      background-color: #d614e1; // #fffb15;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }

  &.AWAITING_ATTENDANT {
    border-bottom: 4px solid;
    border-color: #d614e1; //#fffb15;
    .chamados_totais_value {
      color: #d614e1; //#fffb15;
    }

    &.active {
      background-color: #d614e1; // #fffb15;
      color: white;
      font-weight: bold;

      .chamados_totais_value {
        color: white;
      }
    }
  }


  .chamados_totais_value {
    font-weight: bold;
    font-size: 24px;
  }

  .chamados_totais_label {
    font-size: 12px;
    // max-width: 80px;
  }


}

.chamadoTicketItem {
  .chamadoTicketItem_Title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    padding: 10px 0;
    border-bottom: 4px solid #f4f4f4;

    &.main {
      border-bottom: 4px solid #d614e1;
      background: #eaeaea;
      border-radius: 8px;
      padding: 10px 16px;
      margin-bottom: 8px;
    }

    .chamadoTicketItem_Title_text {

    }

    .chamadoTicketItem_Title_actions {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 10px;
    }
  }

  .chamadoTicketItem_Content {

    padding: 0px 0;
    opacity: 0;
    height: 0;
    transition: all 0.5s;

    &.open {
      padding: 10px 0;
      opacity: 1;
      height: fit-content;
    }
  }
}

.resumo_solicitacao_box {
  background-color: white;
  border-radius: 10px;
  color: #686868;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px;
  border: 1px solid #0000002e;
  box-shadow: 0px 6px 4px 0px #0000002e;

  .resumo_solicitacao_box_title {
    font-size: 24px;
  }
}

.chamado_to_aprove {
  background-color: #f4f4f4;
  font-size: 12px;
  margin-bottom: 2px;
  padding: 10px 16px;
  border-radius: 8px;

  .chamado_to_aprove_title {
    font-size: 18px;
    font-weight: bolde;
    display: flex;
    justify-content: space-between;
  }
}