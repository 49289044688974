@import '../../styles/variaveis';

.feedbackRegistros {
  font-size: 10px;
  padding: 10px;
  max-width: 200px;
  text-align: center;
  margin: auto;
}

.btn-toggle-submenuAdmin {
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  right: -13px;
  top: 33px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px;
  cursor: pointer;

  svg {
    width: 18px;
  }
}

.conteiner-submenu-modal {
  ul {
    margin: 0px;
    padding: 0px 6px 0px 0px;
    list-style: none;
    font-size: 14px;
    // max-height: calc(100vh - 140px);
    // overflow-y: auto;

    // &::-webkit-scrollbar {
    //     width: 6px;
    //     height: 6px;
    // }
    // &::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    // }
    // &::-webkit-scrollbar-thumb {
    //     background-color: $cor-secundary-2;
    //     outline: 1px solid $cor-secundary-2;
    //     border-radius: 20px;
    // }

    li {
      cursor: pointer;
      padding: 0px 8px;
      margin-bottom: 2px;


      .submenu-title-group {
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 2px 0px;

        span {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        div {
          display: flex;
          align-items: center;
          gap: 4px;
          flex: 1;
          padding: 4px 0px;
        }

        &:hover {
          color: $cor-secundary-2;
        }

        &.active {
          &:hover {
            color: white;
          }
        }
      }

      &.active {
        border: 1px solid $cor-secundary-2;
        background-color: $cor-secundary-2;
        color: white;
        border-radius: 20px;

        svg {
          color: white;
        }

        &:hover {
          background-color: $cor-secundary-2;
          color: white;

          .submenu-title-group {
            &:hover {
              color: white;
            }
          }
        }


      }


      svg {
        transition: all 0.3s;
      }
    }
  }

  ul {
    li.show {
      ul.submenu-interno {
        max-height: unset;
      }

      svg {
        transform: rotate(90deg);
      }
    }
  }
}

.wrapper-container-submenuAdmin {
  background-color: rgb(250, 250, 250);
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 9;
  padding-left: 22px;
  padding-right: 14px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px;
  width: 220px;
  transition: all 0.4s;
  // height: 100vh;

  .submenu-close-icon {
    display: none;
    cursor: pointer;
  }


  h2 {
    font-weight: normal;
    font-size: 20px;
  }

  h6 {
    text-transform: uppercase;
    margin: 8px 0px;
  }

  ul {
    margin: 0px;
    padding: 0px 6px 0px 0px;
    list-style: none;
    font-size: 14px;
    max-height: calc(100vh - 140px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $cor-secundary-2;
      outline: 1px solid $cor-secundary-2;
      border-radius: 20px;
    }

    li {
      cursor: pointer;
      padding: 0px 8px;
      margin-bottom: 2px;


      .submenu-title-group {
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 2px 0px;

        span {
          display: flex;
          align-items: center;
          gap: 4px;
        }

        div {
          display: flex;
          align-items: center;
          gap: 4px;
          flex: 1;
          padding: 4px 0px;
        }

        &:hover {
          color: $cor-secundary-2;
        }

        &.active {
          &:hover {
            color: white;
          }
        }
      }

      &.active {
        border: 1px solid $cor-secundary-2;
        background-color: $cor-secundary-2;
        color: white;
        border-radius: 20px;

        svg {
          color: white;
        }

        &:hover {
          background-color: $cor-secundary-2;
          color: white;

          .submenu-title-group {
            &:hover {
              color: white;
            }
          }
        }


      }


      svg {
        transition: all 0.3s;
      }
    }
  }

  ul {
    li.show {
      ul.submenu-interno {
        max-height: unset;
      }

      svg {
        transform: rotate(90deg);
      }
    }
  }

  &.hide {
    width: 0px;
    padding-left: 14px;
    padding-right: 14px;

    .wrapper-content-submenuAdmin {
      width: 0px;
      overflow: hidden;
    }

    .btn-toggle-submenuAdmin svg {
      transform: rotate(180deg);
    }

    // overflow: hidden;
  }
}

.subMenuAdmin-filter-list {
  max-height: 300px;
  overflow-y: auto;
}

@media screen and (min-width: $brackpoint-md) {
  .wrapper-content-submenuAdmin {
    // position: fixed;
    // width: 220px;
  }
}

@media screen and (max-width: $brackpoint-md) {

  .wrapper-container-submenuAdmin {
    padding-top: 76px;
    position: absolute;
    right: 12px;
    top: 76px;
    padding: 0px;
    box-shadow: none;
    background-color: transparent;

    width: 0px;

    .submenu-close-icon {
      display: block;
    }

    ul.submenu-interno li {
      margin: 4px 20px;
    }

    .submenu-content {
      min-height: 50px;
      max-height: 80%;
      overflow: hidden auto;
      padding: 0 20px;

      ul {
        min-height: 50px;
        max-height: 200px;
        overflow: hidden auto;
      }

    }

    .btn-toggle-submenuAdmin {
      width: 25px;
      height: 25px;
      border-radius: 25px;
      right: unset;
      left: -25px;
      top: 0px;
    }

    .wrapper-content-submenuAdmin {
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px;
      // border-radius: 10px;
      // padding: 0px 20px 10px 20px

      border-radius: 0px;
      padding: 4%;
      position: fixed;
      top: 0px;
      left: 0px;
      width: 92%;
      height: 92%;
      display: none;
    }

    &.hide {
      width: 0px;
      padding-left: 14px;
      padding-right: 14px;

      .wrapper-content-submenuAdmin {
        display: none;
      }

      .btn-toggle-submenuAdmin {
        left: 0px;
      }
    }
  }

}
