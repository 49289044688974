@import '../../styles/variaveis';

#listSelectUser {
  .MuiListItem-button {
    background-color: rgba(0, 0, 0, 0.04) !important;
    margin-bottom: 2px;

    &.active {
      background-color: $cor-primary !important;
      color: white;
    }
  }
}

.container-img {
  img {
    max-width: 100%;
    border-radius: 20px;
    border: 1px solid #d5d5d5;
  }
}

#listSelectUser {
  .MuiListItem-button {
    background-color: rgba(0, 0, 0, 0.04) !important;
    margin-bottom: 2px;

    &.active {
      background-color: $cor-primary !important;
      color: white;
    }
  }
}
