@import '../../styles/variaveis';

.chamado-chat-item {
  display: flex;
  position: relative;

  .chamado-chat-box {
    background-color: rgb(233, 233, 233);
    border-radius: 8px;
    padding: 8px 12px;
    width: fit-content;
    max-width: 80%;
    margin-bottom: 10px;
    position: relative;

    .chamado-chat-label {
      font-size: 12px;
    }

    .backbutton {
      opacity: 0;
      background-color: #00000060;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity 0.5s;
    }

    .MuiButton-root.MuiButton-text {
      opacity: 1;
    }

    &:hover {
      .backbutton {
        opacity: 1;
      }
    }
  }

  &.chamado-chat-me {
    justify-content: flex-end;

    .chamado-chat-box {
      background-color: #03e3ff;
    }
  }

}

@media screen and (max-width: $brackpoint-md) {

}
