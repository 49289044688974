@import '../../styles/variaveis';

.tabs-container {
  border-bottom: 3px solid $cor-primary;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  .tabs-item {
    padding: 10px 14px;
    background-color: #D9D9D9;
    transition: all 0.2s;
    cursor: pointer;
    border-radius: 10px 10px 0 0;

    &:hover {
      background-color: $cor-gray-1;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: #D9D9D9;
    }

    &:active,
    &.active {
      color: white;
      background-color: $cor-primary;

      &.disabled {
        cursor: not-allowed;
        background-color: $cor-primary;

      }
    }

  }
}
